export const TrendData= [
    {
        name: "Minions",
        shares: 97
    },{
        name: "Avengers",
        shares: 80.5
    },{
        name: "JamieCodes",
        shares: 75.5
    },{
        name: "ReactJs",
        shares: 72
    },{
        name: "Elon Musk",
        shares: 197
    }
]